import AboutUs from "./AboutUs";
import OurCommitment from "./OurCommitment";
import OurCoreValues from "./OurCoreValues";
import AffiliateProgramSection from "./AffiliateProgramSection";
import OurLeadership from "./OurLeadership";
import OurGallery from "./OurGallery";
import ContactUs from "./ContactUs";
import Banner from "./Banner";
import EstateListing from "./EstateListing";
import OtherListing from "./OtherListing";

function Home() {
  return (
    <>
      {/* <!--============== Banner Start ==============--> */}
      <Banner />
      {/* <!--============== Search Banner End ==============--> */}

      {/* <!--============== Our Estates Start ==============--> */}
      <EstateListing />
      {/* <!--============== Our Estates End ==============--> */}

      {/* <!--============== About Us Start ==============--> */}
      <AboutUs />
      {/* <!--============== About Us End ==============--> */}

      {/* <!--============== Our Commitment Start ==============--> */}
      <OurCommitment />
      {/* <!--============== Our Commitment End ==============--> */}

      {/* <!--============== Our Core Values Start ==============--> */}
      <OurCoreValues />
      {/* <!--============== Our Core Values End ==============--> */}

      {/* <!--============== Affiliate Program Start ==============--> */}
      <AffiliateProgramSection />
      {/* <!--============== Affiliate Program End ==============--> */}

      {/* <!--============== Other Property Listings Start ==============--> */}
      {/* <OtherListing /> */}
      {/* <!--============== Other Property Listings End ==============--> */}

      {/* <!--============== Our Services Start ==============--> */}
      {/* <OurServices /> */}
      {/* <!--============== Our Services End ==============--> */}

      

      {/* <!--============== Our Leadership Start ==============--> */}
      <OurLeadership />
      {/* <!--============== Our Leadership End ==============--> */}

      {/* <!--============== Gallery Start ==============--> */}
      <OurGallery />
      {/* <!--============== Gallery End ==============--> */}

      {/* <!--============== Contact Us Start ==============--> */}
      <ContactUs />
      {/* <!--============== Contact Us End ==============--> */}
    </>
  );
}

export default Home;

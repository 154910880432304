import './aboutus.css'

function AboutUs() {
    return (
        <div id='about-us' className="full-row">
            <div className="container">
                <div className="row">
                    <div className="about-us-grid">
                        <div>
                            <img
                                src="assets/images/abour-us-1.png"
                                alt="Image Not Found!"
                            />
                        </div>

                        <div>
                            <div className="text-center">
                                <h4
                                    className="text-start mb-2 about-us-sub-header-text"
                                >
                                    About us
                                </h4>
                                <h2
                                    className="text-start mb-4 about-us-header-text"
                                >
                                    We turn your dream <br />
                                    <span className="text-primary">properties</span> into reality
                                </h2>
                                <span
                                    className="d-table text-start about-us-description-text"
                                >
                                    At CADLANDS, we’re not just in the business of selling properties; we’re in the business of creating opportunities. Opportunities for families to grow, for investors to prosper, and for communities to thrive.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="about-us-grid">
                        <div>
                            <div className="text-center mb-5">
                                <h2
                                    className="text-start about-us-item-title-text" style={{ color: "#510000" }}
                                >
                                    Our Mission
                                </h2>
                                <span
                                    className="d-table text-start about-us-description-text"
                                >
                                    At Cadlands, our mission is to empower individuals and
                                    businesses to achieve their real estate dreams while building
                                    lasting legacies. We are committed to providing unparalleled
                                    service, leveraging our expertise to navigate the complexities
                                    of purchases within the real estate market.
                                </span>
                                <h2
                                    className="text-primary text-start about-us-item-title-text" style={{ color: "#510000 !important" }}
                                >
                                    Our Vision
                                </h2>
                                <span
                                    className="d-table text-start about-us-description-text"
                                >
                                    At Cadlands, our mission is to empower individuals and
                                    businesses to achieve their real estate dreams while building
                                    lasting legacies. We are committed to providing unparalleled
                                    service, leveraging our expertise to navigate the complexities
                                    of purchases within the real estate market.
                                </span>
                            </div>
                        </div>

                        <div className='bg-white'>
                            <img
                                style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px" }}
                                src="assets/images/abiut-us-2.png"
                                alt="Image Not Found!"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
import './footer.css'

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      {/* <!--============== Footer Section Start ==============--> */}
      <footer
        className="full-row footer-default-dark bg-footer"
        style={{ paddingBottom: "30px" }}
      >
        <div className="container">
          <div className='row logo-container'>
            <div className="col">
              <a href="/">
                <img
                  src="assets/images/logo/cadland-logo.png"
                  className='footer-logo'
                  alt="Image not found!"
                />
              </a>
            </div>

            <div className="col media-widget social-icon-container">
              <a href="#">
                <img
                  className="social-icon"
                  src="assets/images/icon/facebook-icon.png"
                  alt="Image not found!"
                />
              </a>
              <a href="#">
                <img
                  className="social-icon"
                  src="assets/images/icon/x-icon.png"
                  alt="Image not found!"
                />
              </a>
              <a href="#">
                <img
                  className="social-icon"
                  src="assets/images/icon/instagram-icon.png"
                  alt="Image not found!"
                />
              </a>
            </div>
          </div>


          <div className="nav-link-container">
            <a href='/#about-us' className='nav-link-item'>About us</a>
            <a href='/#service' className='nav-link-item'>Services</a>
            <a href='/#contact-us' className='nav-link-item'>Contact us</a>
            <a className='nav-link-item'>Privacy Policy</a>
            <a className='nav-link-item'>Terms of Usage</a>
          </div>

        </div>
      </footer>
      <div className="rights-container">
        <p className='rights-item'>© 2025 Cadlands. All rights reserved.</p>
      </div>

      {/* <!-- Scroll to top --> */}
      <div
        className="whatsapp-chat-button"
        style={{
          position: "fixed",
          right: "20px",
          top: "80%",
          zIndex: 1000,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#680000", // Dark red background from image
          color: "white",
          font: "poppins",
          padding: "10px 15px",
          borderRadius: "30px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)"
        }}
      >
        <span style={{ marginRight: "10px" }}>Chat With Us</span>
        <div style={{
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <svg viewBox="0 0 32 32" style={{ width: "24px", height: "24px", fill: "#25D366" }}>
            <path d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" />
          </svg>
        </div>
      </div>
      {/* <!-- End Scroll To top --> */}
    </>
  );
}

export default Footer;

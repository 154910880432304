import './our-core-values.css'
function OurCoreValues() {
    return (
        <><div id='service' className="full-row">
            <div className="container">
                <div className="row">
                    <div className="col mb-3">
                        <p className="text-center our-services-sub-title-text">
                            Our Services:
                        </p>
                        <h2
                            className="down-line w-100 mx-auto mb-4 text-center w-sm-100 our-services-header-title-text"
                        >
                            We are the BEST in property acquisitions
                        </h2>
                        <span
                            className="d-table w-75 w-sm-100 mx-auto our-services-header-description"
                        >
                            At CADLANDS, we offer more than property sales; we deliver complete real estate solutions aimed at making property ownership, investment, and management smooth, safe, and lucrative.
                        </span>
                    </div>
                </div>

                <div className="our-commitment-grid">
                    <div>
                        <img
                            src="assets/images/our-services.png"
                            className='img'
                            alt="Image Not Found!"
                        />
                    </div>

                    <div>
                        <div className="row row-cols-lg-2 row-cols-sm-1 g-1 justify-content-left">
                            <div>
                                <a
                                    className="text-left d-flex flex-column align-items-left hover-text-dark bg-white transation text-general h-45"
                                    style={{ padding: "12px 24px" }}
                                >
                                    <div className="our-services-item-icon position-relative">
                                        <img
                                            src="assets/images/icon/estate-management.png"
                                            alt="Image Not Found!"
                                        />
                                    </div>
                                    <h6 className="d-block our-services-item-title">
                                        Real Estate Development
                                    </h6>
                                    <div style={{ borderBottom: "1px solid #000" }}>
                                        <p className='our-services-item-description'>
                                            In addition to developing our company estates, we offer our expertise in managing estates for partners within our operational regions. Our focus is on efficiency, organisation, and delivering long-term value.
                                        </p>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a
                                    href="#"
                                    className="text-left d-flex flex-column align-items-left hover-text-dark bg-white transation text-general h-45"
                                    style={{ padding: "12px 24px" }}
                                >
                                    <div className="our-services-item-icon position-relative">
                                        <img
                                            src="assets/images/icon/land-sales.png"
                                            alt="Image Not Found!"
                                        />
                                    </div>
                                    <h6 className="d-block our-services-item-title">
                                        Real Estate Sales
                                    </h6>
                                    <div style={{ borderBottom: "1px solid #000" }}>
                                        <p className='our-services-item-description'>
                                            Land ownership is the foundation of real estate wealth, and at CADLANDS, we make it accessible, secure, and rewarding. Our primary service is the buying and selling of land within our exclusive, well-planned estates.
                                        </p>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a
                                    href="#"
                                    className="text-left d-flex flex-column align-items-left hover-text-dark bg-white transation text-general h-45"
                                    style={{ padding: "12px 24px" }}
                                >
                                    <div className="our-services-item-icon position-relative">
                                        <img
                                            src="assets/images/icon/real-estate-consultancy.png"
                                            alt="Image Not Found!"
                                        />
                                    </div>
                                    <h6 className="d-block our-services-item-title">
                                        Real Estate Advisory
                                    </h6>

                                    <div style={{ borderBottom: "1px solid #000" }}>
                                        <p className='our-services-item-description'>
                                            The Nigerian real estate market can be complex, but with the right support, it opens up a world of possibilities. Our consulting services offer expert insights, strategies, and personalised advice for individuals, investors, and corporations.
                                        </p>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a
                                    href="#"
                                    className="text-left d-flex flex-column align-items-left hover-text-dark bg-white transation text-general h-45"
                                    style={{ padding: "12px 24px" }}
                                >
                                    <div className="our-services-item-icon position-relative">
                                        <img
                                            src="assets/images/icon/portfolio-management-icon.png"
                                            alt="Image Not Found!"
                                        />
                                    </div>
                                    <h6 className="d-block our-services-item-title">
                                        Portfolio Management
                                    </h6>

                                    <div style={{ borderBottom: "1px solid #000" }}>
                                        <p className='our-services-item-description'>
                                            Real estate investment offers a reliable pathway to financial growth, but managing multiple properties can be challenging. At CADLANDS, we assist individuals and investors in strategically building, managing, and optimising their real estate portfolios.
                                        </p>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a
                                    href="#"
                                    className="text-left d-flex flex-column align-items-left hover-text-dark bg-white transation text-general h-45"
                                    style={{ padding: "12px 24px" }}
                                >
                                    <div className="our-services-item-icon position-relative">
                                        <img
                                            src="assets/images/icon/deal-brokerage.png"
                                            alt="Image Not Found!"
                                        />
                                    </div>
                                    <h6 className="d-block our-services-item-title">
                                        Deal Brokerage
                                    </h6>
                                    <div style={{ borderBottom: "1px solid #000" }}>
                                        <p className='our-services-item-description'>
                                            CADLANDS as your reliable intermediary, buying or selling land becomes a smooth and secure process. We serve as advisers and brokers, guiding buyers and sellers toward fair agreements while ensuring transparency and legal integrity in all transactions.
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default OurCoreValues